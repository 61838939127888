<template>
  <div>
    <PrivacyPage />
  </div>
</template>

<script>
import PrivacyPage from "@/components/PrivacyPage.vue";
export default {
  name: "Privacy",
  components: {
    PrivacyPage
  }
};
</script>
